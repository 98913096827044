import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import { resolve } from 'path';
var counter = 0;

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  uploadPercent:any;
  promise:any;
  constructor() { }

  uploadFile(file, customerName) {

    const contentType = file.type;
    const bucket = new S3(
      {
        accessKeyId: 'AKIAR42DZQX75FBB7TFL',
        secretAccessKey: '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',
        region: 'us-west-1'
      }

    );
    const params = {
      //At this point we can insert the custom bucket as the name and everything else stays the same
      Bucket: 'halosight-' + customerName,
      Key: file.name,
      Headers: {
        "Access-Control-Allow-Origin": '*',
        'Access-Control-Allow-Credentials': true,
        'Access-Control-Allow-Headers': true,
        'Access-Control-Allow-Methods': true,

      },
      Body: file,
      ACL: 'public-read',
      ContentType: contentType
    };

    this.promise = bucket.upload(params).on('httpUploadProgress', function (event) {
      // console.log("Uploaded :: " + String((event.loaded * 100) / event.total) + "%");
      this.uploadPercent = ((event.loaded * 100) / event.total) + '%';
      document.getElementById("Upload1").style.width = this.uploadPercent;
      this.uploadPercent = parseInt(this.uploadPercent)
      document.getElementById("uploadPercent").innerHTML = this.uploadPercent + '%';


    }).promise();

    return this.promise;
  }

  abortUpload(file, customerName) {
    console.log("made it into second layer");
    this.promise.abort();

  }

}
