import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { ConstantPool } from '@angular/compiler';


@Component({
  selector: 'app-tool-bar',
  templateUrl: './tool-bar.component.html',
  styleUrls: ['./tool-bar.component.css']
})
export class ToolBarComponent implements OnInit {
  bucket:string;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    //Example for ?bucket=bci -> ?cid=z6263690a -> bci encoded to hex with a z
    this.bucket = this.route.snapshot.queryParamMap.get("bucket");
    if (this.bucket == null) {
      this.bucket = this.route.snapshot.queryParamMap.get("cid");      
    }
    console.log(this.bucket);
    
  }

}

