import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ElementRef } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';

import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

import { ToolBarComponent } from './tool-bar/tool-bar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule } from '@angular/material';
import {HttpClientModule, HttpEvent, HttpErrorResponse, HttpEventType} from "@angular/common/http"
import { AboutUsComponent } from './about-us/about-us.component';
import { InsightsComponent } from './insights/insights.component';
import { DataComponent } from './data/data.component';
import { FAQComponent } from './faq/faq.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { MatFileUploadModule } from 'angular-material-fileupload';
import { DragAndDropDirective } from './drag-and-drop.directive';




@NgModule({
  declarations: [
    AppComponent,
    ToolBarComponent,
    AboutUsComponent,
    InsightsComponent,
    DataComponent,
    FAQComponent,
    FileUploadComponent,
    DragAndDropDirective,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    MatFileUploadModule,
    RouterModule.forRoot([
      { path: '', component: ToolBarComponent },
      { path: 'Insights', component: InsightsComponent },
      { path: 'Data', component: DataComponent },
      { path: 'FAQ', component: FAQComponent },
      { path: 'about-us', component: AboutUsComponent },
      { path: ":bucket", component: ToolBarComponent },
      { path: ":cid", component: ToolBarComponent }

      
    ])
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
