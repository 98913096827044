import { Component, OnInit, Input, HostBinding, HostListener } from '@angular/core';
import { UploadService } from '../services/upload.service';
import { ManagedUpload } from 'aws-sdk/clients/s3';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})

export class FileUploadComponent implements OnInit {
  @Input() customerName: any;

  // fileToUpload: File = null;
  selectedFiles: FileList;
  enableUpload:boolean = false;
  enableEdit: boolean = false;
  successMessage:boolean = false;
  failMessage:boolean = false;
  uploadProgress:number = 0;
  customIconLocation:string;
  dropzoneActive:boolean = false;
  fileArray:Array<File> = [];
  customerList:string[] = ["file-upload-test", "workfront", "bci", "covey", "skipio", "traeger", "built-bar", "xvoyant", "divvy", "google"];
  
 
  constructor(private uploadService: UploadService) {}
  

  ngOnInit() {

    if (this.customerName == null) {
      this.customerName = "file-upload-test";
    }

    else if (this.customerName[0] == 'z') {
      this.customerName = this.customerName.substring(1);
      this.customerName = hex2a(this.customerName);
    }

    else {

    
      
    }

    // this.customerName = "workfront"

    if (this.customerList.includes(this.customerName)) {
      this.customIconLocation = "../../assets/company-logos/halosight-" + this.customerName + ".png"
      console.log(this.customIconLocation)
    }
    else {
      this.customIconLocation = "../../assets/company-logos/doesNotExist.png"
    }
    
  }

  deleteAttachment(index) {
    this.fileArray.splice(index, 1)

    if (this.fileArray.length == 0) {
      this.enableUpload = false;
    }
  }
  upload() {
    var counter = 0;
    this.enableEdit = true;
    for (var i:number = 0; i < this.fileArray.length; i++) {
      const file = this.fileArray[i];
      // console.log(file.name);
      // console.log(file.size)

    this.uploadService.uploadFile(file, this.customerName).then(managedUpload=> {
      // console.log(managedUpload.Key);
      ++counter;
      if (counter === this.fileArray.length) {
        this.enableEdit = false;
        this.successMessage = true;
        this.enableUpload = false;
        this.fileArray = []
      }
    }).catch(error=> {
      this.failMessage = true;
      this.enableEdit = false;
      this.fileArray = []
    });

    }

  }
    
    selectFileDrop(event) {
      // console.log(event);
      // console.log(event.target)
      // console.log("made it into the selectFile function from drop")

      for (let index = 0; index < event.length; index++) {
        const element = event[index];
        this.fileArray.push(element)
      }  

    this.failMessage = false;
    this.successMessage = false;
    this.enableUpload = true;

    }

  /*

  This block of code is for implementation of a cancel button when uploading to S3

  cancelUpload() {
    console.log("made it into first layer");
    this.uploadService.abortUpload(this.file, this.customerName)
  // }

  */

  selectFileClick(event) {
    // console.log(event);
    // console.log(event.target)
    // console.log("made it into the selectFile function click")

    this.selectedFiles = event.target.files;
    for (var i = 0; i < this.selectedFiles.length; i++) {
      this.fileArray.push(this.selectedFiles[i]);
    }

    this.failMessage = false;
    this.successMessage = false;
    this.enableUpload = true;
  }

}

function hex2a(hexx) {
  var hex = hexx.toString();//force conversion
  var str:string = "";
  for (var i = 0; (i < hex.length && hex.substr(i, 2) !== '00'); i += 2)
      str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
  return str.trim();
}
